document.addEventListener("DOMContentLoaded", () => {

    const username = "inwebo"; // Replace with your Github username
    const accessToken = "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"; // Replace with your personal access token
    const apiUrl = "https://api.github.com/graphql";

    const query = `
  query {
    user(login: "${username}") {
      contributionsCollection {
        contributionCalendar {
          colors
          totalContributions
          weeks {
            contributionDays {
              contributionCount
              date
              color
              weekday
            }
          }
        }
      }
    }
  }
`;

    fetch(apiUrl, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
    })
        .then((response) => response.json())
        .then((data) => {
            const contributions = data.data.user.contributionsCollection.contributionCalendar;
            const weeks = contributions.weeks;

            // Create the table structure based on contributions data
            const contributionTable = document.getElementById("contribution-table");
            for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
                const row = document.createElement("tr");
                for (let weekIndex = 0; weekIndex < 53; weekIndex++) {
                    const week = weeks[weekIndex];
                    const contributionDay = week.contributionDays[dayOfWeek];
                    const td = document.createElement("td");

                    if (contributionDay !== undefined) {
                        td.setAttribute('title', contributionDay.date + ' : ' + contributionDay.contributionCount + ' contributions');

                        if (contributionDay.contributionCount !== 0) {
                            td.setAttribute('style', 'background-color:' + contributionDay.color)
                        }
                    }




                    row.appendChild(td);
                }
                contributionTable.appendChild(row);
            }
        })
        .catch((error) => {
            console.error("Error fetching GitHub contributions:", error);
        });
});